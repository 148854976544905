import React from 'react';
import content from "../../assets/content/landing-content.json";
import styled from 'styled-components';
import ImageBusinessSolution from "../../assets/images/business-solution.png";
import { useNavigate } from "react-router-dom";
import Accounting from '../../assets/images/accounting.png';
import Branding from '../../assets/images/branding.png';
import BusinessStrategy from '../../assets/images/businessStrategy.png';
import LegalAndRegulatory from '../../assets/images/legalAndRegulatory.png';
import Grid from '@mui/material/Grid';
// import Vision from '../../assets/images/vision.png';
import imageInfo from "../../assets/content/team-info.json";

const InfoSection = styled.div`
@media (max-width: 599px) {
    padding:20px;
}
@media (max-width: 899px) {
    padding:20px;
}
@media (min-width: 900px) {
    position: absolute;
    top: 8px;
    width: 100%;
}
`;

const Image = styled.img`
//display: none;
max-width:100%;
max-height:100%;
width: auto;
height: auto;
display: block;
mix-blend-mode: hard-light;
    background: rgba(137, 84, 162, 0.4);
`;

const InfoHeader = styled.div`
@media (max-width: 599px) {
    color: #161616;
    width: 100%;
    font-weight: 500;
    font-size: 36px;
}
@media (max-width: 899px) {
    color: #161616;
    width: 100%;
    font-weight: 500;
    font-size: 36px;
}
@media (min-width: 900px) {
    color: #ffffff;
    width: 50%;
    padding: 30px;
    font-size: 48px;
    font-weight: 500;
}
`;

const InfoDetail = styled.div`
@media (max-width: 599px) {
    color: #161616;
    width: 100%;
    font-weight: 300;
    font-size: 16px;
}
@media (max-width: 899px) {
    color: #161616;
    width: 100%;
    font-weight: 300;
    font-size: 16px;
}
@media (min-width: 900px) {
    font-weight: 300;
    font-size: 16px;
    color: #ffffff;
    padding: 0 30px 30px 30px;
    width:50%;}
`;


const AboutSection = styled.div`
@media (max-width: 599px) {
    background-color: #F4F4F4;
    padding:20px;
}
@media (max-width: 899px) {
    background-color: #F4F4F4;
    padding:20px;
}
@media (min-width: 900px) {
    background: rgba(147, 149, 152, 0.25);
    display:inline-flex;
    padding: 20px;
}
`;

const About = styled.div`
@media (max-width: 599px) {
    width: 100%;
}
@media (max-width: 899px) {
    width: 100%;
    display: inline-flex;
}
@media (min-width: 900px) {
    width: 40%;
    margin: auto;
}
`;

const AboutText = styled.div`
font-weight: 500;
color: #939598;

@media (max-width: 599px) {
    font-size: 32px;
}
@media (max-width: 899px) {
    font-size: 32px;
}
@media (min-width: 900px) {
    font-size: 40px;
}
`;

const StratzeeText = styled.div`
font-weight: 500;
color: #89559D;

@media (max-width: 599px) {
    font-size: 32px;
}
@media (max-width: 899px) {
    font-size: 32px;
}
@media (min-width: 900px) {
    font-size: 40px;
}
`;

const Description = styled.div`
font-weight: 300;
font-size: 16px;
color: #161616;
@media (max-width: 599px) {
    width: 100%;
}
@media (max-width: 899px) {
    width: 100%;
}
@media (min-width: 900px) {
    width: 60%;
}
`;

const ReadMore = styled.div`
    height: 48px;
    border-radius: 8px;
        @media (max-width: 599px) {          
            border: 2.5px solid #89559D;
            padding: 0 20px;
        }
        @media (max-width: 899px) {          
            width: 156px;
            border: 2px solid #89559D;
            margin: auto;
        }
        @media (min-width: 900px) {
            width: 156px;
            border: 2px solid #89559D;
            margin: auto;
        }
    `;

const Button = styled.button`
font-size: 16px;
align-items: center;
text-transform: uppercase;
color: #161616;
height:100%;
        @media (max-width: 599px) {         
            font-weight: 400;
            width: 100%;
        }
        @media (max-width: 899px) {         
            font-weight: 400;
            width: 100%;
        }
        @media (min-width: 900px) {
            font-weight: 300;                    
            margin: auto;
        }
    `;

const Item = styled.div`  
text-align: center;
padding-bottom:30px;
`;

const ItemTextHeading = styled.div`
font-weight: 500;
font-size: 18px;
color: #161616;
`;

const ItemTextDescription = styled.div`
width: 300px;
margin: auto;
font-weight: 300;
font-size: 16px;
color: #8D8D8D;
`;

const HeadingText = styled.div`;
 @media (max-width: 599px) {          
            padding: 20px 20px 20px 20px;
        }
        @media (max-width: 899px) {          
            padding: 20px 20px 30px 20px;
        }
        @media (min-width: 900px) {
            padding-top:50px;
            padding-bottom: 40px;
                }

text-align: center;
`;

const HText1 = styled.span`
font-weight: 500;
color: #939598;

@media (max-width: 599px) {
    font-size: 32px;
}@media (max-width: 899px) {
    font-size: 32px;
}
@media (min-width: 900px) {
    font-size: 40px;
}
`;

const HText2 = styled.span`
font-weight: 500;
color: #89559D;

@media (max-width: 599px) {
    font-size: 32px;
}
@media (max-width: 899px) {
    font-size: 32px;
}
@media (min-width: 900px) {
font-size: 40px;
}
`;

const Home = () => {

    const navigate = useNavigate();
    const readMore = (event, goto) => {
        event.preventDefault();
        navigate(goto);
    }

    return (
        <>
            <div className="container-image">
                <div className='background-color'>
                    <Image src={ImageBusinessSolution} alt="Snow" className='image-responsive ' />
                </div>
                <InfoSection>
                    <InfoHeader>
                        {content.infoHeader}
                    </InfoHeader>
                    <InfoDetail>
                        {content.infoDetail}
                    </InfoDetail>
                </InfoSection>
            </div>

            <AboutSection>
                <About>
                    <AboutText>About&nbsp;</AboutText>
                    <StratzeeText>StratZee</StratzeeText>
                </About>
                <Description>
                    <p>{content.aboutUsDescription1}</p>
                    <p>{content.aboutUsDescription2}</p>
                </Description>
            </AboutSection>



            <Grid container columns={{ xs: 4, sm: 12, md: 12 }}>
                <Grid item xs={12} md={12} sm={12}>
                    <HeadingText>
                        <HText1>Our </HText1>
                        <HText2>Services</HText2>
                    </HeadingText>
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                    <Item>
                        <img src={Accounting} alt="" />
                        <ItemTextHeading>VIRTUAL CFO SERVICES</ItemTextHeading>
                        <ItemTextDescription>We at StratZee ensure that our clients have best practices and most efficient management of financial strategy including accounting and other business functions. Our services seamlessly integrate into clients' operations.</ItemTextDescription>
                    </Item>
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                    <Item><img src={LegalAndRegulatory} alt="" />
                        <ItemTextHeading>TAX, LEGAL & REGULATORY</ItemTextHeading>
                        <ItemTextDescription>We at StratZee enable our clients to grow seamlessly by ensuring optimal tax and regulatory strategies including global corporate structuring.</ItemTextDescription>
                    </Item>
                </Grid>

                <Grid item xs={12} md={6} sm={6}>
                    <Item>
                        <img src={BusinessStrategy} alt="" />
                        <ItemTextHeading>STRATEGIC BUSINESS ADVISORY</ItemTextHeading>
                        <ItemTextDescription>We at StartZee deeply analyse the business lifecycle, review the value chain, and frame full-proof business strategies that generate and revitalise value for stakeholders.</ItemTextDescription>
                    </Item>
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                    <Item>
                        <img src={Branding} alt="" />

                        <ItemTextHeading>DIGITAL & BRANDING</ItemTextHeading>
                        <ItemTextDescription>We at StratZee helps bring your unique business vision to life, with state of art design and clean code in all trending tech stack.</ItemTextDescription>
                    </Item>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                    <HeadingText>
                        <ReadMore>
                            <Button type="button" onClick={(e) => readMore(e, '/our-services')}>Read More</Button>
                        </ReadMore>
                    </HeadingText>
                </Grid>
            </Grid>
            <Grid container columns={{ xs: 4, sm: 12, md: 12 }} sx={{ background: 'rgba(147, 149, 152, 0.25)' }}>
                <Grid item xs={12} md={12} sm={12}>
                    <HeadingText>
                        <HText1>Our </HText1>
                        <HText2>Team</HText2>
                    </HeadingText>
                </Grid>
                <Grid item xs={12} sm={12} md={12} sx={{ textAlign: 'right', paddingRight: '20px' }}>
                    <a href={'/our-team'}>View More</a>
                </Grid>
                {imageInfo.teamInfo.map((info, index) => {
                    if (index <= 7) {
                        return (<Grid item xs={2} md={3} sm={3} key={index}>
                            <div className="container-image2">
                                <img src={info.url} alt="Avatar" className="image" />
                                <div className="middle">
                                    <div className="text">{info.name}</div><br />
                                    <div className="text">{info.designation}</div>
                                </div>
                            </div>
                        </Grid>)
                    }
                    return '';
                })}

            </Grid>

            {/* Contact Us */}
            <Grid container columns={{ xs: 4, sm: 12, md: 12 }} alignItems="center">
                <Grid item xs={12} md={6} sm={6}>
                    <HeadingText>
                        <HText1>Do you have</HText1><br></br>
                        <HText2>any question?</HText2>
                    </HeadingText>
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                    <HeadingText>
                        <ReadMore>
                            <Button type="button" onClick={(e) => readMore(e, '/contact-us')}>ORDER A CALL</Button>
                        </ReadMore>
                    </HeadingText>
                </Grid>
            </Grid>
        </>
    )
}

export default Home;
