import { Dialog, DialogTitle } from "@mui/material";

const SimpleDialog = (props) => {
    const { onClose, open } = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>StratZee Privacy Policy</DialogTitle>
            <div className="dialog-text">
                <p>
                    <b>This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. 
                        This electronic record is generated by a computer system and does not require any physical or digital signatures to make the terms of this policy binding.
                    </b>
                </p>
                <p>
                    <b>Affare StratZee LLP</b>, a limited liability partnership incorporated under the provisions of the Limited Liability Partnership Act, 2008 having its registered office at
                    KrishnaShray, H No 3-1/SB/5, Srija Brindavanam Colony, Near Reddy Enclave, Miyapur, Hyderabad – 500049,
                    (“<b>StratZee</b>” or “<b>Company</b>” where such expression shall, unless repugnant to the context thereof, be deemed to include its respective legal heirs, representatives, administrators, permitted successors and assigns).
                    The creation of this Privacy Policy ensures our steady commitment to Your privacy with regard to the protection of Your invaluable information.
                </p>
                <p>
                    StratZee is committed to protecting your privacy. Throughout this policy, the terms “we”, “us”, “our” or “ours” refer to StratZee and the terms “you”, “your” or “yours” refer to you (as the Data Subject). 
                    Client refers to any customer or business that uses StratZee’s services, including but not limited to client’s employees, advisors, contractors, agents, consultants, or others acting on behalf of the client. 
                    Guest refers to our client’s customer.
                </p>
                <p>
                    This “Privacy Policy” describes how StratZee collects, stores, uses, shares and otherwise processes information relating to individuals (“personal data”). 
                    It also describes the rights and choices available to you regarding your personal data.
                    This Privacy Policy applies to the processing of personal data collected by us when you 
                    <ol>
                        <li>
                            Visit <a href="https://www.stratzee.com">www.stratzee.com </a> and our website that display or link to this Privacy Policy; 
                        </li>
                        <li>
                            Receive communications from us, including emails, phone calls ;
                        </li>
                        <li>
                            Use our services, as an authorized user where we act as a “controller” of your personal data as that term is defined under the General Data Protection Regulation 2016/679 (“GDPR”); and
                        </li>
                        <li>
                            Apply directly through the StratZee careers page. 
                        </li>
                    </ol>
                </p>
                <p>
                    For the purposes of the GDPR, California Consumer Privacy Act (CCPA) and other applicable data protection legislations, the data controller for personal data we collect under this Privacy Policy is StratZee.
                </p>
                <p>
                    This Privacy Policy applies to StratZee’s processing of your personal data by us or on our behalf. This Privacy Policy does not apply to:
                    <ul>
                        <li>
                            Personal data processed, stored or hosted by us when we act as a data controller / processor on behalf of our clients in the course of providing our services, in which case the privacy policy of the relevant client(s) will apply, and our data processing agreement with such clients will govern our processing of your personal data.
                        </li>
                    </ul>
                </p>

                <h3> <u>What personal data we collect and process?</u> </h3>
                <p>
                    The personal data we collect directly from you includes identifiers, contact information, professional or employment-related information, commercial information and internet activity information. We collect such information in the following situations:
                    <ul>
                        <li>
                            If you use our “Contact Us” option on the website, we may require that, you provide to us your contact information, such as your first name, last name, full name, email, phone, location, country, business name and shipping address.
                        </li>
                        <li>
                            If you interact with our website or emails, we automatically collect information about your device and your usage of our websites or emails (such as Internet Protocol (IP) addresses or other identifiers, which may qualify as personal data using cookies or similar technologies.
                        </li>
                        <li>
                            If you use and interact with our services, we automatically collect information about your device and your usage of our services through log files and other technologies, some of which may qualify as personal data.
                        </li>
                        <li>
                            If you communicate with us via a phone call from us, we may record your contact details.
                        </li>
                        <li>
                            If you voluntarily submit certain information to our services, such as filling out a survey about your user experience, we collect the information you have provided as part of that request.
                        </li>
                        <li>
                            If you apply directly through the StratZee online recruitment system and careers page, we collect identifiers (including personal and contact details), professional and employment related information (including the information contained in your resume or CV), educational information, and personal characteristics (where the collection of such data is allowed by law).  
                        </li>
                    </ul>
                </p>
                <p>
                    If you provide us with any personal data relating to other individuals, you represent that you have the authority to do so and where required, have obtained the necessary consent, and acknowledge that it may be used in accordance with this Privacy Policy. If you believe that your personal data has been provided to us improperly or want to exercise your rights relating to your personal data, please contact us by using the information in the “Contact us” section below.
                </p>
                <p>
                    We also collect information about you from other sources including third parties and from publicly available information.  We may combine this information with personal data provided by you. This helps us update, expand, and analyse our records and to provide tailored services that may be of interest to you.
                </p>
                <p>
                    <b>Opt In:</b> Start, Subscribe – what they receive once opting in: Thank you for opting for e-mails from StratZee. To opt out at any time reply STOP.
                </p>
                <p>
                    <b>Opt Out:</b> Stop, Unsubscribe - what they receive once opting out: You have successfully opted out from StratZee, to opt back in at any time reply SUBSCRIBE.
                </p>

                <h3> <u> Clients Data </u></h3>
                <p>
                    Some of our services include processing of data, including the personal data of guests, on behalf of our clients in relation to services that we provide. The personal data we collect and process on behalf of our clients may include the following:
                    <ul>
                        <li>
                            <b>Demographic & Identity Data:</b> We may collect personal data of the guest (our client’s customer) such as first name, last name, email, address, contact number, gender, date of birth, photograph, signature, gift card recipient name, gift card recipient address. Additionally, location & device id, are also collected when using mobile apps.
                        </li>
                        <li>
                            <b>Employee Information:</b> We may collect personal data of the employee (our client’s employees, advisors, contractors, agents, consultants, or others acting on behalf of the client) such as first name, last name, email, address, contact number, gender, date of birth, photograph, signature. Additionally, location, device id and IP addresses, are also collected when using mobile apps.
                        </li>
                    </ul>
                </p>
                <p>
                    Save for the limited circumstances set out in this Privacy Policy, we are not the data controller of this information as we do not determine the purposes or the means of the processing.
                </p>

                <h3> <u> What is the basis of collecting and processing your personal data? </u></h3>
                <p>
                    We collect and process your personal data by relying on one or more of the following bases:
                    <ul>
                        <li>
                            The processing is necessary for the purpose of performance of the contract we have with you OR our clients on whose behalf we are serving you.
                        </li>
                        <li>
                            You have explicitly agreed to / consented to us processing your personal data for a specific purpose.
                        </li>
                        <li>
                            The processing is necessary for purposes of informing, promoting, and selling our services to you.
                        </li>
                        <li>
                            The processing is necessary for purposes of employment that you may seek with us.
                        </li>
                        <li>
                            The processing is necessary for the purpose of protecting the StratZee data from threats, violations and breaches if any.
                        </li>
                    </ul>
                </p>

                <h3> <u> How Do We Use Your Personal data?</u></h3>
                <p>
                    We use your personal data for the following purposes:
                    <ul>
                        <li>
                            To verify your identity
                        </li>
                        <li>
                            To deliver our services to you and on behalf of our clients 
                        </li>
                        <li>
                            To communicate with you regarding existing services availed by you, including notifications of any alerts or updates
                        </li>
                        <li>
                            To evaluate, develop and improve our services
                        </li>
                        <li>
                            For market analysis / research and our service analysis 
                        </li>
                        <li>
                            To send you information about our other services which may be of interest to you
                        </li>
                        <li>
                            To handle enquiries and complaints
                        </li>
                        <li>
                            To comply with legal and / or regulatory requirements
                        </li>
                        <li>
                            To investigate, prevent, and / or take action regarding illegal activities, suspected fraud and situations involving potential threats to the safety of any person
                        </li>
                        <li>
                            StratZee has a belief that access, use, preservation or disclosure of such information is reasonably necessary to 
                            <ol>
                                <li>
                                    satisfy any applicable law, regulation, legal process or enforceable governmental request, 
                                </li>
                                <li>
                                    enforce applicable provisions of the Terms of Use Agreement, including investigation of potential violations thereof, 
                                </li>
                                <li>
                                    detect, prevent, or otherwise address fraud, security or technical issues, or 
                                </li>
                                <li>
                                    protect against imminent harm to the rights, property or safety of StratZee, its users or the public as required or permitted by law.
                                </li>
                            </ol>
                        </li>
                    </ul>
                </p>
                <p>
                    If we need to collect and process personal data by law, or under a contract we have entered into with you, and you fail to provide the required personal data when requested, we may not be able to perform our contract with you.
                </p>

                <h3> <u>With whom your personal data may be shared?</u></h3>
                <p>
                    We <b>DO NOT SELL</b> your personal data.
                </p>
                <p>
                    We share your information only in the ways that are described in this Privacy Policy, and only with parties who adopt appropriate confidentiality and security measures. We may share your personal data with the following for the purposes and pursuant to the legal bases described above:
                    <ul>
                        <li>
                            Affiliates: We may share your personal data with the StratZee group entities 
                        </li>
                        <li>
                            Sub-processors: We may use third parties in the provision of our services to you. We may share your personal data with such third parties. Vendors of such third parties / sub-processors may also have access to your information.
                        </li>
                        <li>
                            Third Parties Involved in a Corporate Transaction: If StratZee becomes involved in a merger, acquisition, or any form of sale of some or all its assets, then, in accordance with applicable laws, StratZee will use reasonable efforts to notify you of any transfer of Personal data to an unaffiliated third party.
                        </li>
                        <li>
                            Clients Whom You Are Affiliated: If you use our services as an authorized user, we may share your personal data with your affiliated client is responsible for your access to the services to the extent this is necessary for verifying accounts and activity, investigating suspicious activity, or enforcing our terms and policies.
                        </li>
                        <li>
                            StratZee may share with third-parties certain pieces of aggregated, non-personal data, such as but not limited to the number of users who clicked on an advertisement on the site, the number of users that clicked on any page within the site or aggregated business performance data across a geographical region.
                        </li>
                        <li>
                            We may also share your personal data with law enforcement authorities, government authorities, courts, dispute resolution bodies, regulators, auditors, and any party appointed or requested by applicable regulators to carry out investigations or audits of our activities.
                        </li>
                    </ul>
                </p>

                <h3> <u>Cross-Border Data Transfer</u></h3>
                <p>
                    All personal data we hold about you may be transferred, processed, and stored anywhere in the world, including but not limited to, the United States, India, or other countries, which may have data protection laws that are different from the laws where you live. 
                    Our endeavour is to safeguard your personal data consistent with the requirements of applicable laws. Therefore, your personal data may be processed outside your jurisdiction, and in countries that are not subject to an adequacy decision by the European Commission / CCPA or your local legislature or regulator, and that may not provide for the same level of data protection as your jurisdiction, such as the European Economic Area / CCPA. We ensure that the recipient of your personal data offers an adequate level of protection and security, for instance by entering into the appropriate back-to-back agreements and, if required, standard contractual clauses or an alternative mechanism for the transfer of data as approved by the European Commission / CCPA or other applicable regulator. Where required by applicable law, we will only share, transfer, or store your personal data outside of your jurisdiction with your prior consent.
                </p>

                <h3> <u>PERSONAL DATA ACCESS AND CORRECTION</u></h3>
                <p>
                    We will provide access to, update and amend, and/or delete the Personal Information in accordance with applicable laws.
                    You can request access to, and have the opportunity to update and amend your Personal Information, and, you can exercise the other rights acknowledged by applicable data protection legislation, including, for example, objection to and blocking of the processing of your Personal Information, by contacting us at info@stratzee.com or by sending a letter to Attn: KrishnaShray, H No 3-1/SB/5, Srija Brindavanam Colony, Near Reddy Enclave, Miyapur, Hyderabad – 500049.
                    For security reasons, We reserves the right to take steps to authenticate the identity of the requesting party before providing access to Personal Information in accordance with this section of the Privacy Policy.
                </p>

                <h3> <u>Use of Cookies and Other Tracking Mechanisms</u></h3>
                <p>
                    We may use cookies and other tracking mechanisms on our website and other digital properties to collect data about you. When you visit our websites, we, or an authorized third party, may place a cookie on your device that collects information, including personal data, about your online activities over time and across different sites. Cookies allow us to track use, infer-browsing preferences, and improve and customize your browsing experience.
                </p>
                <p>
                    Do Not Track (“DNT”) is a privacy preference that users can set in certain web browsers. Please note that we do not respond to or honour DNT signals or similar mechanisms transmitted by web browsers. Please note, by blocking or deleting cookies and similar technologies used on our website, you may not be able to take full advantage of the websites.
                </p>

                <h3> <u>Your Privacy Rights</u></h3>
                <p>
                    You have the following Privacy Rights, and we commit to provide you with the same:
                    <ul>
                        <li>
                            <b>Right of Access:</b> You have the right to get access to your personal data that is with us along with other supporting information.
                        </li>
                        <li>
                            <b>Right to Rectification:</b> You have the right to ask us to rectify your personal data that is with us that you think is inaccurate. You also have the right to ask us to complete your personal data that you think is incomplete.
                        </li>
                        <li>
                            <b>Right to Erasure:</b> You have the right to ask us to erase your personal data that is with us under certain circumstances.
                        </li>
                        <li>
                            <b>Right to Restriction of Processing:</b> You have the right to ask us to restrict the processing of your personal data under certain circumstances.
                        </li>
                        <li>
                            <b>Right to Data Portability:</b> You have the right to ask that we transfer the personal data you gave us to another organisation, or to you, under certain circumstances.
                        </li>
                        <li>
                            <b>Right to Object:</b> You have the right to object to the processing of your personal data under certain circumstances.
                        </li>
                        <li>
                            <b>Right to not be subjected to Automated individual decision-making:</b> You have the right to not to be subjected to automated individual decision-making including profiling. Automated decision-making currently does not take place on our website or in our services
                        </li>
                        <li>
                            <b>Right to lodge a complaint with the Supervisory/Regulatory Authority:</b> You have the right to lodge a complaint with the appropriate supervisory/regulatory authority.
                        </li>
                    </ul>
                </p>
                <p>
                    For more information on exercising your Privacy Rights please contact us at <a href="mailto:privacy@stratzee.com">privacy@stratzee.com</a>
                </p>
                <p>
                As described above, we may also process personal data submitted by or for a client to our services. To this end, if not stated otherwise in this Privacy Policy or in a separate disclosure, we process such personal data as a processor on behalf of our clients (and their affiliates) who is the controller of the personal data. 
                We are not responsible for and have no control over the privacy and data security practices of our clients, which may differ from those explained in this Privacy Policy. If your personal data has been submitted to us by or on behalf of a client and you wish to exercise any rights you may have under applicable data protection laws, please enquire with them directly. 
                Because we may only access a client’s data upon their instructions, if you wish to make your request directly to us, please provide us the name of the client who submitted your personal data to us by writing to us at <a href="mailto:privacy@stratzee.com">privacy@stratzee.com</a>. 
                We will refer your request to that client and will support them as needed in responding to your request within a reasonable timeframe.
                </p>

                <h3> <u>Marketing / Promotional communications and Opt-out	</u></h3>
                <p>
                If we process your personal data for the purpose of sending you marketing communications, you may choose to stop receiving these communications by following the unsubscribe instructions included in these emails or by replying back with your unsubscribe request or by contacting us at <a href="mailto:privacy@stratzee.com">privacy@stratzee.com</a>. 
                Please note that opting out of marketing communications does not opt you out of receiving important business communications related to your current relationship with us, such as communications about your subscriptions or event registrations, service announcements or security information.
                </p>

                <h3> <u>Retention of personal data</u></h3>
                <p>
                We retain your personal data for as long as it is required for the purposes stated in this Privacy Policy. 
                Sometimes, we may retain your data for longer periods as permitted or required by law, such as if required in connection with a legal claim or proceeding, to enforce our agreements, or to comply with other legal obligations. 
                When we no longer have a legitimate need to process your data, we will delete or anonymize your data from our active databases.
                </p>

                <h3> <u>Children</u></h3>
                <p>
                Our website and services are not directed at children. We do not knowingly collect personal data from children under the age of 18 or such other applicable age of consent for privacy purposes in relevant individual jurisdictions. If you are a parent or guardian and believe your child has provided us with personal data, please contact us by using the information in the “Contact Us” section below and we will take steps to delete their personal data from our systems.
                </p>

                <h3> <u>Notification of Changes</u></h3>
                <p>
                We regularly review and update our Privacy Policy to ensure it is up-to-date and accurate. 
                Any changes we may make to this Privacy Notice in future will be posted on this page. If we make a material update, we may provide you with notice prior to the update taking effect, such as by posting a conspicuous notice on our website or by contacting you directly, or where required under applicable law and feasible, seek your consent to these changes.
                </p>

                <h3> <u>Additional Disclosures for California Residents</u></h3>
                <p>
                    The California Consumer Privacy Act (“CCPA”) requires businesses to disclose whether they sell personal data. As a business covered by the CCPA, we do not sell personal data. 
                    We may share personal data with third parties or allow them to collect personal data from our services if those third parties are authorized service providers or business partners who have agreed to our contractual limitations as to their retention, use, and disclosure of such Personal data, or if you use our services to interact with third parties or direct us to disclose your personal data to third parties.
                </p>
                <p>
                    Consistent with the CCPA, job applicants, current and former employees and contractors, and subjects of certain business-to-business communications acting solely in their capacity as representatives of another business, are not considered consumers for purposes of this section or the rights described herein.
                </p>
                <p>
                    California law requires that we detail the categories of personal data that we disclose for certain “business purposes,” such as to service providers that assist us with securing our services or marketing our products, and to such other entities as described in this Privacy Policy. 
                    We disclose the following categories of Personal data for our business purposes:
                    <ul>
                        <li>
                            Identifiers;
                        </li>
                        <li>
                            Commercial information;
                        </li>
                        <li>
                            Internet activity information;
                        </li>
                        <li>
                            Financial information;
                        </li>
                        <li>
                            Professional and employment-related information;
                        </li>
                        <li>
                            Education information; and
                        </li>
                        <li>
                            Inferences drawn from any of the above information categories.
                        </li>
                    </ul>
                </p>
                <p>
                California law grants state residents certain rights, including the rights to access specific types of personal data, to learn how we process personal data, to request deletion of personal data, and not to be denied goods or services for exercising these rights.
                </p>
                <p>
                If you are a California resident under the age of 18 and have registered for an account with us and has posted content or information on or through the services, you can request that such information be removed by contacting us using the information in the “Contact Us” section above. Please note that your request does not ensure complete or comprehensive removal of the content or information, because, for example, some of your content may have been reposted by another user.
                </p>
                <p>
                For information on how to exercise your rights, please refer to the “Your Privacy Rights” section above. If you are an authorized agent wishing to exercise rights on behalf of a California resident, please contact us using the information in the “Contact Us” section above and provide us with a copy of the consumer’s written authorization designating you as their agent. We may need to verify your identity and place of residence before completing your rights request.
                </p>
                <p>
                California law permits customers in California to request certain details about how their personal information is shared with third parties, and in some cases affiliates, if personal information is shared for those third parties’ or affiliates’ own direct marketing purposes. We do not share personal information with third parties or affiliates for those third parties’ or affiliates’ own direct marketing purposes. 
                Californians may request information about our compliance with this law by contacting us at <a href="mailto:privacy@stratzee.com">privacy@stratzee.com</a> or by sending a letter to our office address.
                </p>

                <h3> <u>GDPR Data Protection Rights</u></h3>
                <p>
                    We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
                </p>
                <p>
                    The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.
                </p>
                <p>
                    The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.
                </p>
                <p>
                    The right to erasure – You have the right to request that we erase your personal data, under certain conditions.
                </p>
                <p>
                    The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.
                </p>
                <p>
                    The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.
                </p>
                <p>
                    The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
                </p>
                <p>
                    If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
                </p>

                <h3> <u>Contact Us</u></h3>
                <p>
                    If there are any questions regarding this privacy policy or data protection issues, you may contact us at <a href="mailto:privacy@stratzee.com">privacy@stratzee.com</a> or at the below mentioned address
                </p>
                <p>
                    <b>Address:</b> KrishnaShray, H No 3-1/SB/5, Srija Brindavanam Colony, Near Reddy Enclave, Miyapur, Hyderabad – 500049
                </p>
                <p>
                    <a href="https://www.stratzee.com">www.stratzee.com </a>
                </p>
            </div>


        </Dialog>
    );
}

export default SimpleDialog;