import { MenuItem, TextField } from '@mui/material'
import React from 'react'
// import styled from 'styled-components'

import { useDropdownInput } from 'react-google-forms-hooks'
import styled from 'styled-components';
// const ErrorLabel = styled.span`
// color: red;
// padding: 10px 0;
// `

const STextField = styled(TextField)`
& label.Mui-focused {
  color:  rgb(137, 85, 157);
}
& .MuiOutlinedInput-root {
  &.Mui-focused fieldset {
    border-color:  rgb(137, 85, 157);
  }
}
`;
export default function DropdownInput({ id, label }) {
  const { register, options } = useDropdownInput(id)
  // const [find, setFind] = React.useState('');

  // const handleChange = (event) => {
  //   setFind(event.target.value);
  // };



  return (

    <>
      <STextField
        select
        id="outlined-basic"
        fullWidth
        label={label}
        variant="outlined"
        {...register()}
        defaultValue=""
      >
        {options.map((option) => (
          <MenuItem key={option.label} value={option.label}>
            {option.label}
          </MenuItem>
        ))}
      </STextField>


      {/* error={error !== undefined} */}
      {/* <FormControl fullWidth>
        <InputLabel id="demo-multiple-name-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id={id}
          value={find}
          label={label}
          input={<OutlinedInput label={label} />}
          {...register({ onChange: handleChange })}
        >
          {options.map((o) => (
            <MenuItem
              key={o.label}
              value={o.label}
            >
              {o.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
      {/* {error && <FormHelperText>This is a required field!</FormHelperText>} */}

    </>
  )
}
