import React from 'react';
import AboutUsImage from '../../assets/images/about-us.png';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import { useMediaQuery } from '@mui/material';

const Header = styled.div`
        font-weight: 500;
        font-size: 48px;
        color: #000000;
        @media (max-width: 599px) {
        }
        @media (max-width: 899px) {
        }
        
        @media (min-width: 900px) {
            padding: 20px 60px 0px 60px; 
        }

        `;
const Image = styled.img`
       max-width:100%;
        max-height:100%;
        width: auto;
        height: auto;
        display: block;
        mix-blend-mode: hard-light;
        background: rgba(137, 84, 162, 0.4);  
    `;

const SubHeader = styled.div`
        font-weight: 700;
        font-size: 16px;
        color: #000000;
        // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
       
        @media (max-width: 599px) {
            padding: 20px 20px 0 20px; 
        }
        @media (max-width: 899px) {
            padding: 20px 20px 0 20px; 
        }
        @media (min-width: 900px) {
            padding: 0px 60px; 
        }
        `;
const Paragraph = styled.div`
        font-weight: 300;
        font-size: 16px;
        color: #000000;
        // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        @media (max-width: 599px) {
            padding: 20px; 
        }
        @media (max-width: 899px) {
            padding: 0 20px 20px 20px; 
        }
        @media (min-width: 900px) {
            padding: 0 60px 20px 60px; 
        }
        `;
const HeadingText = styled.div`
@media (max-width: 599px) {
    padding-top:20px;
}
@media (max-width: 899px) {
    padding-top:20px;
}
 @media (min-width: 900px) {
    padding-top:50px;
    padding-bottom: 40px;
}
        text-align: center;
    `;

const HText1 = styled.span`
        font-weight: 500;
        color: #939598;

        @media (max-width: 599px) {
            font-size: 32px;
        }
        @media (max-width: 899px) {
            font-size: 32px;
        }
        @media (min-width: 900px) {
            font-size: 40px;
        }
    `;

const HText2 = styled.span`
        font-weight: 500;
        color: #89559D;

        @media (max-width: 599px) {
            font-size: 32px;
        }
        @media (max-width: 899px) {
            font-size: 32px;
        }
        @media (min-width: 900px) {
        font-size: 40px;
        }
    `;
const AboutUs = () => {
    const desktop = useMediaQuery('(min-width:900px)');
    const tablet = useMediaQuery('(min-width:600px)');
    const mobile = useMediaQuery('(max-width:599px)');

    return (
        <>
            <Grid container columns={{ xs: 4, md: 12 }}>
                <div className="container-image">
                    <div className='background-color'>
                        <Image src={AboutUsImage} alt="Snow" className='image-responsive ' />
                    </div>
                    <div className="bottom-left">
                        <div className="header-services">
                            {(desktop || tablet) && <span>About Us</span>}
                        </div>
                    </div>
                </div>
                {/* </Grid> */}
                {mobile && <Grid item xs={12} md={12} sm={12} >
                    <HeadingText>
                        <HText1>About </HText1>
                        <HText2>Us</HText2>
                    </HeadingText>
                </Grid>}

                <Grid item xs={12} md={12} sm={12}>
                    {(desktop || tablet) && <Header></Header>}
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                    <SubHeader>How StratZee came to be</SubHeader>

                    <Paragraph>
                        StratZee was founded by multi-dimensional, experienced professionals coming from different sectors, with a shared vision - to help clients achieve operational excellence.
                    </Paragraph>
                    <Paragraph>
                        We at StratZee take care of our clients business needs like a walk in the park, by aligning our vision with their goals! We ensure that our clients have best practices and most efficient management of business needs including financial strategy, accounting and other business requirements. We work as an extended arm of our client by integrating our services seamlessly into clients' operations.
                    </Paragraph>

                    <SubHeader>Vision</SubHeader>

                    <Paragraph>
                        Our vision is to be recognised as leading professional services firm making curated and bespoke solution reach at grassroot level, helping build a strong base for society.
                    </Paragraph>

                    <SubHeader>Mission</SubHeader>

                    <Paragraph>
                        Our mission is to improve operational efficiency and effectiveness of our clients’ business by providing solution-mix of conventional methodologies, contemporary strategies, and transformative technology. We believe that transformation leads to measurable operational or financial results.
                    </Paragraph>

                    <SubHeader>Approach</SubHeader>

                    <Paragraph>
                        We achieve this by providing thought leadership; specialist subject matter expertise who help businesses function, adapt, and evolve so they can benefit society; a global delivery model; and automated technology solutions. Our solution-mix is crated to ensure that our clients achieve more for less.
                    </Paragraph>

                    <SubHeader>Values</SubHeader>

                    <Paragraph>
                        Integrity, Responsibility, Excellence, Pioneering, and unity.
                    </Paragraph>

                </Grid>

            </Grid>
        </>
    )
}

export default AboutUs;