import React from 'react';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import ServicesImage from '../../assets/images/our-services.png';
import BusinessAdvisory from '../../assets/images/business-advisory.png';
import Contract from '../../assets/images/contract.png';
import InvestmentAdvisory from '../../assets/images/investment-advisory.png';
import { useMediaQuery } from '@mui/material';

const Header = styled.div`
font-weight: 500;
color: #000000;
@media (max-width: 599px) {
    font-size: 24px;
    padding: 0 20px 20px 20px; 
}
@media (max-width: 899px) {
    font-size: 24px;
    padding: 0 20px 20px 20px; 
}
@media (min-width: 900px) {
    font-size: 30px;
    padding: 20px 60px 0px 60px; 
}
`;

const Image = styled.img`
       max-width:100%;
max-height:100%;
width: auto;
height: auto;
display: block;
mix-blend-mode: hard-light;
    background: rgba(137, 84, 162, 0.4);
    
`;

const SubHeader = styled.div`
font-weight: 700;
font-size: 16px;
color: #000000;
// text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

@media (max-width: 599px) {
    padding: 0px 20px 0 20px; 
}
@media (max-width: 899px) {
    padding: 0px 20px 0 20px; 
}
@media (min-width: 900px) {
    padding: 0px 60px; 
}
`;
const Paragraph = styled.div`
font-weight: 300;
font-size: 16px;
color: #000000;
// text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
@media (max-width: 599px) {
    padding: 0 20px 20px 20px; 
}
@media (max-width: 899px) {
    padding: 0 20px 20px 20px; 
}
@media (min-width: 900px) {
    padding: 20px 60px; 
}

`;

const SimpleParagraph = styled.div`
font-weight: 300;
font-size: 16px;
color: #000000;
// text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
@media (max-width: 599px) {
    padding: 0 20px 20px 0px; 
}
@media (max-width: 899px) {
    padding: 0 20px 20px 0px; 
}
@media (min-width: 900px) {
    padding-top:20px;
}
`;

const SimpleSubHeader = styled.div`
font-weight: 700;
font-size: 16px;
color: #000000;
// text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
@media (max-width: 599px) {
    padding: 0px 20px 0px 0px; 
}
@media (max-width: 899px) {
    padding: 0px 20px 0px 0px; 
}
@media (min-width: 900px) {
    padding-top:20px;
    padding-left:40px;
    padding-right: 20px;
}
`;

const OrderedListHeader = styled.div`font-weight: 700;
font-size: 16px;
color: #000000;
// text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
@media (max-width: 599px) {
    padding: 0px 20px 0px 0px; 
}
@media (max-width: 899px) {
    padding: 0px 20px 0px 0px; 
}
@media (min-width: 900px) {
    padding-top:20px;
}`;


const List = styled.div`
font-weight: 300;
font-size: 16px;
color: #000000;
// text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
@media (max-width: 599px) {
    padding-bottom:20px;
}
@media (max-width: 899px) {
    padding-bottom:20px;
}
@media (min-width: 900px) {
    padding-left:60px;
}

`;

const HeadingText = styled.div`
        @media (max-width: 599px) {
        padding-top:20px;
        padding-bottom:20px;

    }
    @media (max-width: 899px) {
        padding-top:20px;
        padding-bottom:20px;

    }
     @media (min-width: 900px) {
        padding-top:50px;
        padding-bottom: 40px;
    }
        text-align: center;
    `;

const HText1 = styled.span`
        font-weight: 500;
        color: #939598;

        @media (max-width: 599px) {
            font-size: 32px;
        }
        @media (max-width: 899px) {
            font-size: 32px;
        }
        @media (min-width: 900px) {
            font-size: 48px;
        }
    `;

const HText2 = styled.span`
        font-weight: 500;
        color: #89559D;

        @media (max-width: 599px) {
            font-size: 32px;
        }
        @media (max-width: 899px) {
            font-size: 32px;
        }
        @media (min-width: 900px) {
        font-size: 40px;
        }
    `;

const SimpleList = styled.div`
font-weight: 300;
font-size: 16px;
color: #000000;
// text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
@media (max-width: 599px) {
    padding: 0px 20px 20px 0px   ; 
}
@media (max-width: 899px) {
    padding: 0px 20px 20px 0px   ; 
}
@media (min-width: 900px) {
    padding: 0px 60px 40px 0px; 
}
`;

const ImageContainerLeft = styled.div`
    @media (max-width: 599px) {
    }
    @media (max-width: 899px) {
    }
    @media (min-width: 900px) {
        padding-left: 60px; 
    }
`;


const OurServices = () => {
    const desktop = useMediaQuery('(min-width:900px)');
    const tablet = useMediaQuery('(max-width:899px)');
    const mobile = useMediaQuery('(max-width:599px)');
    return (
        <Grid container columns={{ xs: 4, md: 12, sm: 12 }}>
            <div className="container-image">
                <div className='background-color'>
                    <Image src={ServicesImage} alt="Snow" className='image-responsive ' />
                </div>
                <div className="bottom-left">
                    <div className="header-services">

                        {(desktop || (tablet && !mobile)) && <span>Our Services</span>}
                    </div>
                </div>
            </div>
            {/* </Grid> */}
            {mobile && <Grid item xs={12} md={12} sm={12}>
                <HeadingText>
                    <HText1>Our </HText1>
                    <HText2>Services</HText2>
                </HeadingText>
            </Grid>}
            <Grid item xs={12} md={12} sm={12}>
                {/* {`${desktop}${tablet}${mobile}`} */}
                <Header>Virtual CFO Services</Header>
                <Paragraph>
                    Compliance is a complex process and we at StratZee take care of your compliance like a walk in the park, by aligning our vision with your goals! As an extended finance function of our client’s, with a priority of achieving compliance in a more effective and efficient way!!.
                    We at StratZee ensure that our clients have best practices and most efficient management of financial strategy including accounting and other business functions. Our services seamlessly integrate into clients' operations.
                </Paragraph>
                <Paragraph>
                    Our experts act as a single point of contact to perform legal, regulatory, taxation and compliance activities and advise our client’s as required to enable overall effective compliance management as it said “money saved is money earned”
                    APPROACH - We pre-define the processes and controls across various business domains including financial, accounting, compliance and taxation operations to weed out inefficiencies and ensure maximum cost savings to achieve sustained growth. Our subject matter experts cover regulatory and reporting requirements and effectively streamline processes and controls.
                </Paragraph>

                <SubHeader>Services:</SubHeader>
                <List>
                    <ul>
                        <li>Accounting advisory & bookkeeping</li>
                        <li>GST and Tax Filings</li>
                        <li>Regular labour compliances viz. Provident Fund, Employee State Insurance, Professional Tax etc. </li>
                        <li>Prevention af sexual harassment (POSH) at work place</li>
                        <li>Payroll processing and compliance</li>
                        <li>Shops and establishments registration and compliance</li>
                        <li>Secretarial compliances</li>
                        <li>Creation of Standard Operating Procedures (SOPs)</li>
                        <li>Management Information System (MIS) for managerial and operating levels</li>
                        <li>Tax exemptions, deductions & structuring</li>
                        <li>Effective decision making</li>
                        <li>Overall financial discipline and management</li>
                    </ul>
                </List>
                <Paragraph>
                    Our CEO Quote: If compliance is costly, try non-compliance!!
                </Paragraph>
            </Grid>
            <Grid item container xs={12} md={12} sm={12} sx={{ background: desktop ? 'rgba(147, 149, 152, 0.25)' : '' }}>
                <Grid item xs={12} md={6} sm={12} sx={{ margin: 'auto', paddingTop: desktop ? "50px" : "", paddingBottom: desktop ? "50px" : "20px", paddingLeft: tablet ? "30px" : '' }}>
                    <ImageContainerLeft>
                        <div className="container-image">
                            <div className='background-color image-dimension'>
                                <Image src={BusinessAdvisory} alt="Snow" className='image-responsive ' />
                            </div>
                        </div>
                    </ImageContainerLeft>
                </Grid>
                <Grid item xs={12} md={6} sm={12} sx={{ paddingTop: desktop ? "30px" : "", paddingBottom: desktop ? "30px" : "" }}>

                    <Header>Strategic Business Advisory</Header>
                    <Paragraph>
                        Business Process Re-engineering and Risk Management
                    </Paragraph>

                    <Paragraph>

                        We deeply analyse the business lifecycle, review the value chain, and frame full-proof business strategies that generate and revitalise value for stakeholders. Our strategies are custom made and unique based on business and its lifecycle stage. In the process, we assist businesses manage risks from an operational, functional and geographical perspective.
                    </Paragraph>

                    <Paragraph>

                        APPROACH - We bring efficiencies and devise effective processes to define and align stakeholders value from the business. We perform detailed risk assessment of all factors such as markets, sales channels, product lifecycle, skill sets, competence, capacity, technology, corporate finance, political, functional etc.

                    </Paragraph>

                    <SubHeader>Services:</SubHeader>
                    <List>
                        <ul>
                            <li>Business process review and analysis including Implementation </li>
                            <li>Business Risk Management</li>
                            <li>Data and Analytics</li>
                            <li>Governance Risk and Compliance</li>
                            <li>Fraud and Other Risk Assessments</li>
                        </ul>
                    </List>
                    <Paragraph>
                    </Paragraph>
                </Grid>
            </Grid>
            <Grid item xs={12} md={12} sm={12} sx={{ paddingBottom: mobile ? '20px' : '' }}>
                <div className="container-image">
                    <div className='background-color'>
                        <Image src={Contract} alt="Snow" className='image-responsive ' />
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
                <Header>Tax, Legal & Regulatory</Header>
                <SimpleSubHeader>
                    <ol>
                        <li>
                            Tax advisory services
                            <SimpleParagraph>
                                We at StratZee enable our clients to grow seamlessly by ensuring optimal tax and regulatory strategies including global corporate structuring  with low or no risk of litigation;  Integrated with other strategies, tax optimization adds significant value and enables clients for all-round growth.
                            </SimpleParagraph>
                            <SimpleParagraph>
                                APPROACH - We commence by performing diagnostic review of current business practices and processes, source of investment, need for repatriation, global markets etc; identifying gaps, if any and best-case scenarios, tax cost optimization and risk management
                            </SimpleParagraph>
                            <OrderedListHeader>Services</OrderedListHeader>
                            <SimpleList>
                                <ul>
                                    <li>Tax savings and risk management</li>
                                    <li>Global tax and regulatory strategies</li>
                                    <li>Corporate Strategy to complement the tax strategy</li>
                                    <li>Investment and repatriation strategy</li>
                                    <li>Multi-nation optimization advisory</li>
                                    <li>M&A and Base Erosion and Profit Shifting advisory</li>
                                    <li>Compliance and legal support</li>
                                    <li>Structuring of legal compliances to business verticals</li>
                                </ul>
                            </SimpleList>
                        </li>
                        <li>
                            Tax Dispute Resolution
                            <SimpleParagraph>
                                The businesses that are not compliant with the laws are prone to disputes from the regulatory authorities. The disputes not only have financial implications, but also have far reaching reputational risks for the business. We manage the disputes in line with the applicable regulations and ensure the abidance of applicable regulations and minimise the chances of repetition.
                            </SimpleParagraph>
                            <SimpleParagraph>
                                APPROACH - The best approach to avoid any potential disputes is to review current positions from litigation stand-point and provide guidance. Our experts have deep domain knowledge and are well equipped to provide assistance in strategising defence, representation before authorities etc.
                            </SimpleParagraph>
                            <OrderedListHeader>Services</OrderedListHeader>
                            <SimpleList>
                                <ul>
                                    <li>Strategising the case and drafting the submissions</li>
                                    <li>Representation before authorities / tribunals / courts</li>
                                    <li>Providing guidance on the wayforward</li>
                                </ul>
                            </SimpleList>

                        </li>
                        <li>
                            Legal Structuring
                            <SimpleParagraph>
                                It is important for businesses to strategize and re-strategize the approach with the dynamic landscape, and laws being interpreted differently by various forums. Our legal experts evaluate the requirements keeping in mind the ever changing laws.
                            </SimpleParagraph>
                            <SimpleParagraph>
                                APPROACH - We formulate a robust and holistic legal structure by identifying loose ends, if any and devising new policies which are in line with the overall vision of the client.
                            </SimpleParagraph>
                            <OrderedListHeader>Services</OrderedListHeader>
                            <SimpleList>
                                <ul>
                                    <li>Corporate and commercial advisory</li>
                                    <li>Mergers and acquisitions</li>
                                    <li>Dispute resolution and advisory</li>
                                    <li>Estate planning, trust, and private client services</li>
                                </ul>
                            </SimpleList>
                        </li>
                        <li>
                            Transfer Pricing
                            <SimpleParagraph>
                                We at StratZee assist our clients in maintenance of robust documentation, framing multi-jurisdictional transfer pricing policies in line with arm’s length principle, which aids in strategic alignment of organisation goals. We also guide and assist in crafting sound dispute resolution strategies.
                            </SimpleParagraph>
                            <SimpleParagraph>
                                APPROACH - We perform detailed year on year functional analysis of each transaction and identify the risks involved to conclude the arm’s length adherence.
                            </SimpleParagraph>
                            <OrderedListHeader>Services</OrderedListHeader>
                            <SimpleList>
                                <ul>
                                    <li>Transfer pricing compliance including maintenance of 3-tier documentation</li>
                                    <li>Value Chain Transformations</li>
                                    <li>Transfer pricing dispute resolution</li>
                                    <li>Advance pricing agreement and mutual agreement procedures</li>
                                </ul>
                            </SimpleList>
                        </li>
                        <li>
                            Intellectual Property
                            <SimpleParagraph>We at StratZee provide end-to-end legal solutions across the lifecycle of intellectual property rights, from creation and protection, to monetisation and enforcement. We endeavour to balance commercial realities with legal rationality and offer nuanced and holistic solutions.
                            </SimpleParagraph>
                            <SimpleParagraph>APPROACH - We focus on our clients’ needs and take the time to understand their businesses and concerns. Our approach is solution oriented and cost effective, founded on a strong framework of business ethics and diligently fashioned operational procedures.
                            </SimpleParagraph>
                            <OrderedListHeader>Services</OrderedListHeader>
                            <SimpleList>
                                <ul>
                                    <li>Trademark registration, objection, opposition, and renewal</li>
                                    <li>Copyright registration</li>
                                    <li>Design registration</li>
                                    <li>Provisional Patent</li>
                                    <li>Patent registration</li>
                                </ul>
                            </SimpleList>
                        </li>
                    </ol>
                </SimpleSubHeader>
            </Grid>
            <Grid item xs={12} md={12} sm={12} sx={{ paddingBottom: mobile ? '20px' : '' }}>
                <div className="container-image">
                    <div className='background-color'>
                        <Image src={InvestmentAdvisory} alt="Snow" className='image-responsive ' />
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
                <Header>Investment Advisory</Header>
                <Paragraph>
                    In short it is advising what to do with your money. We are professionals with a fundamental understanding of money management, which we use to construct personalized financial plans that are targeted to achieve your financial goals. APPROACH - Our advisory services include wealth management, budgeting, savings, and more - it is a more holistic solution. We will regularly check on with you and evaluate the plan as needed. We will also help you maintain a balanced approach towards your cash flows and savings, thereby ensuring that your key financial health ratios are in check. Most importantly, the process of financial planning exponentially increases your chances of meeting your long-term goals in a systematic and disciplined manner. To sum up, we provide a roadmap towards a better financial future, reduced financial stress, and a greatly enhanced investment planning journey.
                </Paragraph>
                <SubHeader>Services:</SubHeader>
                <List>
                    <ul>
                        <li>Goal Identification & Quantification</li>
                        <li>Financial Planning</li>
                        <li>Portfolio creation and structuring</li>
                        <li>Mutual Funds</li>
                        <li>Equity </li>
                        <li>Bonds</li>
                        <li>ETFs</li>
                        <li>Equity Derivatives </li>
                        <li>Insurance</li>
                    </ul>
                </List>
                <Paragraph>
                </Paragraph>
            </Grid>
        </Grid>
    )
};

export default OurServices;