import { TextField } from '@mui/material'
import React from 'react'

import { useShortAnswerInput } from 'react-google-forms-hooks'
import styled from 'styled-components';
// import styled from 'styled-components'
// const ErrorLabel = styled.span`
// color: red;
// padding: 10px 0;
// `;

const STextField = styled(TextField)`
& label.Mui-focused {
  color:  rgb(137, 85, 157);
}
& .MuiOutlinedInput-root {
  &.Mui-focused fieldset {
    border-color:  rgb(137, 85, 157);
  }
}
`;

export default function ShortAnswerInput({ id, label, rules, success }) {
  const { register, error } = useShortAnswerInput(id)

  function validationRules(rules) {
    let updatedRules = {};

    updatedRules['minLength'] = rules.minLength;
    updatedRules['maxLength'] = rules.maxLength;
    updatedRules['required'] = rules.required;

    if (rules.pattern) {
      updatedRules['pattern'] = {
        value: new RegExp(rules.pattern.value),
        message: rules.pattern.message
      }
    }

    return updatedRules;
  }

  return (
    <STextField
      error={error?.message.length > 0}
      id="outlined-basic"
      label={label}
      variant="outlined"
      {...register(validationRules(rules))}
      fullWidth
      helperText={error?.message}
    />
  )
}
