
import React, { useState } from 'react';
import '../../App.css';
import Logo from '../../assets/images/Stratzee_Logo.png';
import MenuIcon from '../../assets/images/icons-menu.png';
import {
    Box, Drawer, List, ListItem, ListItemButton, ListItemText
} from '@mui/material';
import { useNavigate } from "react-router-dom";

const Header = () => {

    const [menu, setMenu] = useState(false);
    const navigate = useNavigate();

    const navigateTo = (e, navAddress) => {
        e.preventDefault();
        navigate(navAddress);
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setMenu({ ...menu, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {[
                    { 'label': 'Home', 'action': '/' },
                    { 'label': 'About Us', 'action': 'about-us' },
                    { 'label': 'Our Team', 'action': 'our-team' },
                    { 'label': 'Services', 'action': 'our-services' },
                    // { 'label': 'Knowledge', 'action': 'knowledge' },
                    // { 'label': 'Career', 'action': 'career' },
                    { 'label': 'Contact Us', 'action': 'contact-us' }].map((text) => (
                        <ListItem key={text.label} >
                            <ListItemButton href={text.action}>
                                <ListItemText primary={text.label} />
                            </ListItemButton>
                        </ListItem>
                    ))}
            </List>
        </Box>
    );

    return (
        <Box sx={{ display: 'inline-flex', width: 1 }}>
            <span className="App-Header">
                <img src={Logo} alt="" onClick={(e) => navigateTo(e, '/')} />
            </span>
            <span className="App-Icon-Menu">
                <img src={MenuIcon} alt="" onClick={toggleDrawer('right', true)}></img>
                <Drawer
                    anchor='right'
                    open={menu['right']}
                    onClose={toggleDrawer('right', false)}
                >
                    {list('right')}
                </Drawer>
            </span>
        </Box>
    );
}

export default Header;