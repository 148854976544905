import React from 'react';
import imageInfo from "../../assets/content/team-info.json";
import Grid from '@mui/material/Grid';
import styled from 'styled-components';
import OurTeamImage from '../../assets/images/our-team.png';
import useMediaQuery from '@mui/material/useMediaQuery';

const HeadingText = styled.div`

text-align: center;
    @media (max-width: 599px) {
        padding-top:20px;
        padding-bottom:20px;

    }
     @media (min-width: 600px) {
        padding-top:50px;
        padding-bottom: 40px;
    }
`;

const HText1 = styled.span`
font-weight: 500;
color: #939598;

@media (max-width: 599px) {
    font-size: 32px;
}
@media (min-width: 600px) {
    font-size: 40px;
}
`;

const HText2 = styled.span`
font-weight: 500;
color: #89559D;

@media (max-width: 599px) {
    font-size: 32px;
}
@media (min-width: 600px) {
font-size: 40px;
}
`;
const Image = styled.img`
max-width:100%;
max-height:100%;
width: auto;
height: auto;
display: block;
mix-blend-mode: hard-light;
background: rgba(137, 84, 162, 0.4);
`;

const OurTeam = () => {

    const desktop = useMediaQuery('(min-width:600px)');
    const mobile = useMediaQuery('(max-width:599px)');

    return (
        <Grid container columns={{ xs: 4, md: 12 }}>
            <div className="container-image">
                <div className='background-color'>
                    <Image src={OurTeamImage} alt="Snow" className='image-responsive ' />
                </div>
                <div className="bottom-left">
                    <div className="header-services">
                        {desktop && <span>Our Team</span>}
                    </div>
                </div>
            </div>
            {mobile && <Grid item xs={12} md={12}>
                <HeadingText>
                    <HText1>Our </HText1>
                    <HText2>Team</HText2>
                </HeadingText>
            </Grid>}

            {imageInfo.teamInfo.map((info, index) => {
                return (<Grid container item xs={12} md={12}
                    key={index}
                    sx={{ backgroundColor: desktop ? (index % 2 === 0 ? '' : '#F4F4F4') : '', p: '10px' }}>
                    <Grid item xs={12} md={3} sx={{ paddingRight: "10px" }}>
                        <img src={info.url} alt="Avatar" className="image-team" />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <div className="candidate-name">{info.name}</div>
                        <div className="candidate-designation">{info.designation}</div>
                        <div className="candidate-description">{info.description1}</div>
                        <div className="candidate-description">{info.description2}</div>
                    </Grid>
                </Grid>)
            })}
        </Grid>
    )
}

export default OurTeam;