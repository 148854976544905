import React from 'react';
import './App.css';
import Home from './components/home/Home';
import { Routes, Route } from "react-router-dom";
import OurServices from './components/our-services/OurServices';
import AboutUs from './components/about-us/AboutUs';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import { Box } from '@mui/material';
import OurTeam from './components/our-team/OurTeam';
// import Knowledge from './components/knowledge/Knowledge';
// import Career from './components/career/career';
import ContactUs from './components/contact-us/ContactUs';
import Privacy from './components/privacy/Privacy';

function App() {
  return (
    <Box>
      <Header></Header>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/our-team" element={<OurTeam />} />
        <Route path="/our-services" element={<OurServices />} />
        {/* <Route path="/knowledge" element={<Knowledge />} />
        <Route path="/career" element={<Career />} /> */}
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
      <Footer></Footer>
    </Box>
  );
}

export default App;
