import React from 'react';

const Privacy = () => {
    return (
        <>
            Privacy page
        </>
    )
}

export default Privacy;