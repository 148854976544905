import { TextField } from '@mui/material'
import React from 'react'

import { useLongAnswerInput } from 'react-google-forms-hooks'
import styled from 'styled-components';
const STextField = styled(TextField)`
& label.Mui-focused {
  color:  rgb(137, 85, 157);
}
& .MuiOutlinedInput-root {
  &.Mui-focused fieldset {
    border-color:  rgb(137, 85, 157);
  }
}
`;
export default function LongAnswerInput({ id, label }) {
  const { register } = useLongAnswerInput(id)

  return (
    <STextField
      label={label}
      multiline
      {...register()}
      fullWidth
    />
  )
}
