import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import Logo from '../../assets/images/footer-logo.png';
import { useMediaQuery } from '@mui/material';
import SimpleDialog from '../dialog/Dialog';


const Item = styled.div`
    padding: 20px;
`;

const ContactsHeading = styled.div`
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    color: #161616;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    @media (max-width: 599px) {
        padding-left:20px;
    }
    @media (max-width: 899px) {
        padding-left:20px;
    }
`;


const Heading = styled.div`
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    color: #161616;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
`;

const HeadingParent = styled.div`
    padding-bottom:20px;
`;

const HeadingChild = styled.a`
    font-weight: 300;
    font-size: 14px;
    color: #161616;
    padding-left: 20px;
    text-decoration: none;
`;

const ItemText = styled.div`
font-weight: 300;
font-size: 16px;
color: #161616;
`;

const ItemTextRight = styled(ItemText)`
@media (max-width: 599px) {}
@media (max-width: 899px) {
}
@media (min-width: 900px) {
    text-align:right;
}
`;

const ContactH = styled.div`
    font-weight: 300;
    font-size: 16px;
    color: #878787;
    @media (max-width: 599px) {
        padding-left:20px;
    }
    @media (max-width: 899px) {
        padding-left:20px;
    }
`;

const ContactAddress = styled.div`
    font-weight: 300;
    font-size: 14px;
    color: #161616;
    padding-bottom: 20px;
    @media (max-width: 599px) {
        padding-left:20px;
    }
    @media (max-width: 899px) {
        padding-left:20px;
    }
`;

const Social = styled.span`
    padding-left: 20px;
`;

const openSocial = (url) => {
    window.open(url);
}

export default function Footer() {
    const desktop = useMediaQuery('(min-width:900px)');
    const tablet = useMediaQuery('(max-width:899px)');
    const mobile = useMediaQuery('(max-width:599px)');
    const [open, setOpen] = useState(false);

    const openDialog = (e) => {
        e.preventDefault();
        setOpen(true);
    }

    const handleClose = (value) => {
        setOpen(false);
    };

    return (
        <Box sx={{ background: 'rgba(147, 149, 152, 0.25)', p: '1/5' }}>
            <Grid container columns={{ xs: 4, md: 12 }} className="footer-container">
                <Grid container item xs={12} md={6} sm={12} sx={{ p: '1/5' }}>
                    <Grid item xs={6} md={12} sm={6}>
                        <Item><img src={Logo} alt="" /></Item>
                    </Grid>
                    {(mobile || tablet) && <Grid item xs={6} md={12} sm={6} sx={{ m: 'auto', padding: "0 20px 0 50px" }}>
                        <Social><img onClick={() => openSocial('https://www.facebook.com/stratzee')} src={require('../../assets/images/facebook.png')} alt="@affareStratzee" /></Social>
                        <Social><img onClick={() => openSocial('https://www.linkedin.com/company/stratzee')} src={require('../../assets/images/linkedin.png')} alt="@stratzee" /></Social>
                        <Social><img onClick={() => openSocial('https://twitter.com/affareStratzee')} src={require('../../assets/images/twitter.png')} alt="@affareStratzee" /></Social>
                    </Grid>}
                    {desktop && <Grid item xs={12} md={12} sm={12}>
                        <Item>
                            <ItemText>
                                <b>India:</b> Hyderabad (HO) | Bengaluru | Guntur | Jharsuguda (Odisha) | Mumbai | Pune | Patna | Raipur | Vadodara
                                <br /><b>Overseas:</b> Australia | Taiwan | Canada
                            </ItemText>
                        </Item>
                    </Grid>}
                </Grid>
                {desktop && <Grid item xs={12} md={3} sm={12}>
                    <Grid item xs={12} md={12} sm={12}>
                        <Heading>Navigation</Heading>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                        <HeadingParent><HeadingChild href='/about-us'>About</HeadingChild></HeadingParent>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                        <HeadingParent><HeadingChild href='/our-team'>Our Team</HeadingChild></HeadingParent>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                        <HeadingParent><HeadingChild href='/our-services'>Services</HeadingChild></HeadingParent>
                    </Grid>
                    {/* <Grid item xs={12} md={12}>
                        <HeadingChild>Career</HeadingChild>
                    </Grid> */}
                    <Grid item xs={12} md={12} sm={12}>
                        <HeadingParent><HeadingChild href='/contact-us'>Contact Us</HeadingChild></HeadingParent>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                        <HeadingParent><HeadingChild href='/' onClick={e => openDialog(e)}>Privacy Policy</HeadingChild></HeadingParent>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                        <Social><img onClick={() => openSocial('https://www.facebook.com/stratzee')} src={require('../../assets/images/facebook.png')} alt="@affareStratzee" /></Social>
                        <Social><img onClick={() => openSocial('https://www.linkedin.com/company/stratzee')} src={require('../../assets/images/linkedin.png')} alt="@stratzee" /></Social>
                        <Social><img onClick={() => openSocial('https://twitter.com/affareStratzee')} src={require('../../assets/images/twitter.png')} alt="@affareStratzee" /></Social>

                    </Grid>
                </Grid>}
                <Grid item xs={12} md={3} sm={12}>
                    <Grid item xs={12} md={12} sm={12}>
                        <ContactsHeading>CONTACTS</ContactsHeading>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                        <ContactH>Address</ContactH>
                        <ContactAddress>Affare StratZee LLP, KrishnaShray, H No 3-1/SB/5, Srija Brindavanam Colony, Near Reddy Enclave, Miyapur, Hyderabad - 500 049</ContactAddress>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                        <ContactH>EMAIL</ContactH>
                        <ContactAddress>info@stratzee.com</ContactAddress>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                        <ContactH>PHONES</ContactH>
                        <ContactAddress>+91-9440404136</ContactAddress>
                    </Grid>
                </Grid>
                <Grid item container xs={12} md={12} sm={12}>
                    <Grid item xs={12} md={6} sm={12}>
                        <Item>
                            <ItemText>
                                ©  2023 Affare StratZee LLP. All Rights Reserved.
                            </ItemText>
                        </Item>
                    </Grid>
                    <Grid item xs={12} md={6} sm={12}>
                        <Item>
                            <ItemTextRight>
                                Image: Pexels.com
                            </ItemTextRight>
                        </Item>
                    </Grid>
                </Grid>
            </Grid>

            <SimpleDialog
                open={open}
                onClose={handleClose}
            />
        </Box>
    );
}
