import React, { useState } from 'react';
import styled from 'styled-components';

import { GoogleFormProvider, useGoogleForm } from 'react-google-forms-hooks';
import form from '../../scripts/contactUs.json';

import ShortAnswerInput from '../ui-components/ShortAnswerInput';
import LongAnswerInput from '../ui-components/LongAnswerInput';
import DropdownInput from '../ui-components/DropdownInput';
import { Button, Grid, useMediaQuery } from '@mui/material';
import ContactUsImage from '../../assets/images/contact-us.png';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import rules from "../../scripts/rules/validation-rules.json";
import SimpleDialog from '../dialog/Dialog';

const Form = styled.form`
  max-width: 600px;
  margin: 0 auto;
  padding: 50px 20px;
`;

const QuestionContainer = styled.div`
  margin-top: 20px;
`;

const HeadingText = styled.div`
        @media (max-width: 599px) {
        padding-top:20px;
        padding-bottom:20px;

    }
     @media (min-width: 600px) {
        padding-top:50px;
        padding-bottom: 40px;
    }
        text-align: center;
    `;

const HText1 = styled.span`
        font-weight: 500;
        color: #939598;

        @media (max-width: 599px) {
            font-size: 32px;
        }
        @media (min-width: 600px) {
            font-size: 40px;
        }
    `;

const HText2 = styled.span`
        font-weight: 500;
        color: #89559D;

        @media (max-width: 599px) {
            font-size: 32px;
        }
        @media (min-width: 600px) {
        font-size: 40px;
        }
    `;
const Image = styled.img`
        max-width:100%;
        max-height:100%;
        width: auto;
        height: auto;
        display: block;
        mix-blend-mode: hard-light;
        background: rgba(137, 84, 162, 0.4);  
`;

const SubHeader = styled.div`
        font-weight: 700;
        font-size: 16px;
        color: #000000;
        // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        text-align:center;
        @media (max-width: 599px) {
          padding: 0 20px;

  
      }
       @media (min-width: 600px) {
        padding: 20px;

      }
        `;
const ContactUs = () => {
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState(false);

  const desktop = useMediaQuery('(min-width:600px)');
  const mobile = useMediaQuery('(max-width:599px)');

  const methods = useGoogleForm({ form });

  const handleClose = (value) => {
    setOpen(false);
  };

  const onSubmit = async (data) => {
    await methods.submitToGoogleForms(data);
    setSuccess(true);
    methods.reset();
  }

  const returnToAnotherQuery = () => {
    setSuccess(false);
    methods.reset();
  }

  const openDialog = (e) => {
    e.preventDefault();
    setOpen(true);
  }

  return (
    <>
      <div className="container-image">
        <div className='background-color'>
          <Image src={ContactUsImage} alt="Snow" className='image-responsive ' />
        </div>
        <div className="bottom-left">
          <div className="header-services">
            {desktop && <span>Contact Us</span>}
          </div>
        </div>
      </div>
      {mobile && <Grid item xs={12} md={12} sm={12}>
        <HeadingText>
          <HText1>Contact </HText1>
          <HText2>Us</HText2>
        </HeadingText>
      </Grid>}
      <SubHeader>Please help us respond to your comment by supplying the following details</SubHeader>

      {success && <div className="success-block">
        <div className="success-text">
          <div className="success-para">
            <div>
              <CheckCircleIcon className='success-icon' />
            </div>
            <div>
              <div>Thanks for contacting us! </div>
              <div>We will be in touch with you shortly.</div>
            </div>
          </div>
        </div>

        <QuestionContainer>
          <Button type='button' variant="contained" onClick={returnToAnotherQuery} >Another Query?</Button>
        </QuestionContainer>
      </div>}
      {!success && <GoogleFormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} md={12} sm={12} key={form.fields[0].id}>
              <DropdownInput id={form.fields[0].id} label={form.fields[0].label} />
            </Grid>
            <Grid item xs={4} md={12} sm={12} key={form.fields[1].id}>
              <DropdownInput id={form.fields[1].id} label={form.fields[1].label} />
            </Grid>
            <Grid item xs={4} md={6} sm={4} key={form.fields[2].id}>
              <ShortAnswerInput value={success} id={form.fields[2].id} label={form.fields[2].label} rules={rules[form.fields[2].label]} />
            </Grid>
            <Grid item xs={4} md={6} sm={4} key={form.fields[3].id}>
              <ShortAnswerInput id={form.fields[3].id} label={form.fields[3].label} rules={rules[form.fields[3].label]} />
            </Grid>
            <Grid item xs={4} md={6} sm={4} key={form.fields[4].id}>
              <ShortAnswerInput id={form.fields[4].id} label={form.fields[4].label} rules={rules[form.fields[4].label]} />
            </Grid>
            <Grid item xs={4} md={6} sm={4} key={form.fields[5].id}>
              <ShortAnswerInput id={form.fields[5].id} label={form.fields[5].label} rules={rules[form.fields[5].label]} />
            </Grid>
            <Grid item xs={4} md={12} sm={12} key={form.fields[6].id}>
              <ShortAnswerInput id={form.fields[6].id} label={form.fields[6].label} rules={rules[form.fields[6].label]} />
            </Grid>
            <Grid item xs={4} md={12} sm={12} key={form.fields[7].id}>
              <LongAnswerInput id={form.fields[7].id} label={form.fields[7].label} rules={rules[form.fields[7].label]} />
            </Grid>
            <Grid item xs={4} md={12} sm={12} sx={{ fontSize: "10px" }}>
              By submitting your personal details, you acknowledge that you have read the <a href="/" onClick={e => openDialog(e)}>Privacy Statement</a> and that you consent to our processing data in accordance with the Privacy Statement (including international transfers).
              If you change your mind at any time about wishing to receive the information from us, you can send us an email message using the Contact Us page.
            </Grid>
          </Grid>
          <QuestionContainer>
            <Button fullWidth={mobile} type='submit' variant="contained" className='bg-btn'>Submit</Button>
          </QuestionContainer>
        </Form>
      </GoogleFormProvider >}

      <SimpleDialog
        open={open}
        onClose={handleClose}
      />
    </>
  )
}

export default ContactUs;